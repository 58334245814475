




















































import {
  defineComponent,
  markRaw,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch,
  watchEffect,
} from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getStudyVideoDetail, getStudyVideos } from './api';
import Player from 'xgplayer';
import VClamp from 'vue-clamp';

export default defineComponent({
  components: { VClamp },
  beforeRouteUpdate(to) {
    if (typeof to.params.id !== 'undefined') {
      this.refresh(Number(to.params.id));
    }
  },
  beforeRouteLeave(to, __, next) {
    this.destroyPlayer();
    next();
  },
  setup(_, { root }) {
    const { state } = useMainStore();
    const { result: detail, refresh } = useApi(
      (id: number) => getStudyVideoDetail(id),
      null,
      false,
    );
    let player: Player | undefined = undefined;

    watchEffect(() => {
      if (detail.value) {
        nextTick(() => {
          if (player) {
            player.destroy();
          }
          player = new Player({
            id: 'player',
            url: detail.value!.playUrl,
            videoInit: true,
            lang: 'zh-cn',
            fluid: true,
          });
        });
      }
    });

    const { result: videos } = useApi(
      async () => (await getStudyVideos(1, 4)).filter(v => v.id !== Number(root.$route.params.id)),
      [],
    );

    onMounted(() => {
      if (typeof root.$route.params.id !== 'undefined') {
        refresh(Number(root.$route.params.id));
      }
    });

    return {
      destroyPlayer: () => {
        if (player) {
          player.destroy();
        }
      },
      detail,
      videos,
      refresh,
      ...toRefs(state),
      toDetail: (id: number) => {
        root.$router.push({ name: 'StudyVideo', params: { id: id.toString() } });
      },
    };
  },
});
